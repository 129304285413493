<template>
	<v-container
		fluid
		fill-height
		class="pa-0 ma-0"
		style="height: 100vh"
		:style="validated ? 'background:#94ce9a' : ''"
	>
		<v-row no-gutters>
			<v-col cols="2" style="height: 100vh">
				<v-btn
					color="#564592"
					width="100%"
					height="calc((15vw)"
					elevation="0"
					dark
					@click="$emit('close'), reset()"
				>
					<v-icon style="font-size: 8vw">mdi-close</v-icon>
				</v-btn>
				<v-btn
					v-if="!validated"
					color="#CA7DF9"
					width="100%"
					height="calc((15vw)"
					elevation="0"
					dark
					:disabled="!valid"
					class="mr-4"
					@click="validate"
					:style="validated ? 'background:#94ce9a' : ''"
				>
					<v-icon style="font-size: 8vw">mdi-email-arrow-right-outline</v-icon>
				</v-btn>
				<v-btn
					v-if="validated"
					width="100%"
					color="#94ce9a"
					height="calc((15vw)"
					elevation="0"
					dark
					@click="reset()"
				>
					<v-icon style="font-size: 8vw">mdi-arrow-u-left-top</v-icon>
				</v-btn>
			</v-col>
			<v-col
				class="d-flex justify-center"
				cols="7"
				md="6"
				offset="1"
				offset-md="2"
			>
				<v-row no-gutters align="center" justify="center">
					<span v-if="validated" class="text-center">
						<p>Message Sent!</p>
					</span>
					<v-form
						ref="form"
						v-show="!validated"
						v-model="valid"
						data-netlify="true"
						data-netlify-honeypot="bot-field"
						lazy-validation
						name="contact-me"
						style="flex-grow: 1"
					>
						<input type="hidden" name="Contact Form" value="contact-me" />
						<v-text-field
							v-model="form.name"
							:rules="nameRules"
							label="Name"
							name="Name"
							required
							height="120px"
						></v-text-field>

						<v-text-field
							v-model="form.email"
							:rules="emailRules"
							label="E-mail"
							name="Email"
							required
							height="120px"
						></v-text-field>

						<v-textarea
							v-model="form.message"
							:rules="messageRules"
							label="Message"
							name="Message"
							height="120px"
							class="mt-16"
						></v-textarea>
					</v-form>
				</v-row>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import axios from "axios";
export default {
	name: "ContactForm",

	data: () => ({
		valid: false,
		validated: false,
		nameRules: [(v) => !!v || "Name is required"],
		messageRules: [(v) => !!v || "Message is required"],
		emailRules: [
			(v) => !!v || "E-mail is required",
			(v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
		],
		form: {
			name: "",
			email: "",
			message: "",
		},
	}),
	methods: {
		encode(data) {
			return Object.keys(data)
				.map(
					(key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`
				)
				.join("&");
		},
		validate() {
			this.$refs.form.validate();
			if (this.$refs.form.validate()) {
				const axiosConfig = {
					header: { "Content-Type": "application/x-www-form-urlencoded" },
				};
				axios.post(
					"/",
					this.encode({
						"form-name": "contact-me",
						...this.form,
					}),
					axiosConfig
				);
				this.validated = true;
			}
		},
		reset() {
			this.$refs.form.reset();
			this.validated = false;
		},
	},
};
</script>

<style lang="scss">
.v-text-field > .v-input__control > .v-input__slot:before {
	border-bottom: 3px solid #fff;
}
.v-application {
	label {
		font-size: 28px;
		height: 28px;
		overflow: visible !important;
	}
	input {
		max-height: 100px;
		font-size: 36px;
	}
	textarea {
		height: 200px;
		font-size: 36px;
		margin-top: 20px;
	}
	.v-text-field__details {
		height: 22px;
		overflow: visible !important;
	}
	.error--text {
		.v-messages__message,
		label,
		.v-input__slot {
			color: #f89898 !important;
			caret-color: #f89898 !important;
		}
	}
	.v-messages__message {
		font-size: 20px;
	}
}
</style>
