<template>
	<v-app>
		<v-app-bar
			inverted-scroll
			elevation="0"
			fixed
			color="deep-purple accent-4"
			dark
			scroll-target="#scrolling-techniques-6"
		>
			<v-app-bar-nav-icon></v-app-bar-nav-icon>

			<v-toolbar-title>Collapsing Bar</v-toolbar-title>
		</v-app-bar>

		<v-main>
			<v-container class="ma-0 pa-0" fluid>
				<v-sheet style="background: #edf67d" class="words">
					<h1
						aria-label="i am lam"
						style="
							color: #724cf9;
							font-family: 'Quicksand', sans-serif;
							font-size: 25rem;
							font-weight: 700;
							line-height: 1;
						"
					></h1>

					<p>
						<br /><br />
						///** //// **/// <br /><br />Hello, <br />My name is
						<span style="color: #724cf9">Gar&nbsp;Lam</span> and I'm an
						Full-stack / interactive developer and digital intergration
						specialist.

						<br /><br />

						<br /><br />

						////////////// <br />
					</p>
				</v-sheet>
				<v-sheet style="background: #edf67d">
					<v-item-group active-class="primary">
						<v-container>
							<v-row>
								<v-col v-for="n in 3" :key="n" cols="12" md="4">
									<v-item v-slot="{ active }">
										<v-card
											class="d-flex align-center"
											color="#724cf9"
											elevation="0"
											dark
											@click.stop="(contact = true), (dialog = true)"
											width="100%"
											height="calc((50vw - 20px)"
											max-height="500px"
											:ripple="false"
											aria-label="contact me button"
										>
											<v-scroll-y-transition>
												<div
													v-if="active"
													class="text-h2 flex-grow-1 text-center"
												>
													Active
												</div>
											</v-scroll-y-transition>
										</v-card>
									</v-item>
								</v-col>
							</v-row>
						</v-container>
					</v-item-group>
				</v-sheet>
				<v-sheet style="background: #edf67d">
					<div class="words">
						<br />
						<h2
							style="
								color: #724cf9;
								font-family: 'Quicksand', sans-serif;
								font-size: 2rem;
								font-weight: 600;
								line-height: 1;
							"
						>
							Tech Stack
						</h2>
						<p>
							Vue.js, JavaScript, Scss, WordPress, Php, Netlify, node.js<br /><br />

							////*/////*/// <br /><br />
						</p>
						<h2
							style="
								color: #724cf9;
								font-family: 'Quicksand', sans-serif;
								font-size: 2rem;
								font-weight: 600;
								line-height: 1;
							"
						>
							Methology
						</h2>
						<p>
							JamStack, Agile, Acessibility

							<br /><br />
						</p>
						<h2
							style="
								color: #724cf9;
								font-family: 'Quicksand', sans-serif;
								font-size: 2rem;
								font-weight: 600;
								line-height: 1;
							"
						>
							Technology
						</h2>
						<p>
							This site is powered by a headless REST API via Heroku, Vue.js &
							Netlify Forms

							<br /><br />
						</p>
					</div>
				</v-sheet>

				<v-dialog
					v-model="dialog"
					fullscreen
					hide-overlay
					transition="dialog-bottom-transition"
					class="ma-0 pa-0"
				>
					<v-card v-if="contact" color="#724CF9" dark class="ma-0 pa-0">
						<ContactForm @close="dialog = false" />
					</v-card>
				</v-dialog>
			</v-container>
		</v-main>

		<v-footer class="ma-0 pa-0" style="overflow: hidden">
			<v-btn
				color="#724cf9"
				elevation="0"
				dark
				@click.stop="(contact = true), (dialog = true)"
				width="50%"
				height="calc((50vw - 20px)"
				max-height="500px"
				:ripple="false"
				aria-label="contact me button"
			>
				<v-icon style="font-size: 20vw"> mdi-email-open </v-icon>
			</v-btn>
			<v-btn
				color="#F896D8"
				elevation="0"
				dark
				@click.stop="dialog = true"
				width="50%"
				height="calc((50vw - 20px)"
				max-height="500px"
				:ripple="false"
				aria-label="back to top"
			>
				<v-icon style="font-size: 20vw"> mdi-arrow-up </v-icon>
			</v-btn></v-footer
		>
	</v-app>
</template>

<script>
import ContactForm from "./components/ContactForm";

export default {
	name: "App",

	components: {
		ContactForm,
	},
	mounted() {
		this.splitLetters();
		this.animateWord();
	},

	data: () => ({
		dialog: false,
		title: "i am Lam",
	}),

	methods: {
		splitLetters() {
			let $word = document.querySelector(".words h1");
			let sentence = this.title.split(" ");
			let newSentence = "";
			for (let i = 0; i < sentence.length; ++i) {
				let wordArray = sentence[i].split("");
				let letters = "";
				wordArray.forEach((l) => {
					letters = `${letters}<span class="letter" data-letter="${l}">${l}</span>`;
				});
				newSentence = newSentence + `<span class="word"> ${letters}</span>`;
				$word.innerHTML = newSentence;
			}
		},
		animateWord() {
			let $obj = document.querySelector(".words h1");
			const this_ = this;
			let $letters = $obj.querySelectorAll(".letter");
			$letters.forEach(function (element, i) {
				setTimeout(function () {
					this_.animateLetter(element);
				}, 200 * i);
			});
		},

		animateLetter(letter) {
			let alpha = ["す", "べ", "て", "の", "て", "こ", "す"];

			letter.classList.add("changing");
			let original = letter.getAttribute("data-letter");
			let i = 0;
			let letterInterval = setInterval(function () {
				let randomLetter = alpha[Math.floor(Math.random() * alpha.length)];
				letter.innerHTML = randomLetter;
				if (i == 5) {
					clearInterval(letterInterval);
					letter.innerHTML = original;
					letter.classList.remove("changing");
				}

				++i;
			}, 260);
		},
	},
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP&family=Quicksand:wght@400;600;700&display=swap");
@import "./scss/variables.scss";

#app {
	margin: 0;
	padding: 0;
	background: #edf67d;
	color: #724cf9;
	overflow-x: hidden;
}

.v-application {
	font-family: $body-font-family;
	font-size: $font-size-root;
	.v-btn {
		border-radius: $border-radius-root;
	}
	h1 {
		text-transform: uppercase;
	}
}

.words {
	position: relative;
	width: 30%;
	left: 10%;
	text-align: left;
}

.word {
	display: inline-block;
	cursor: pointer;
}
</style>
